import AgentForm from "../../components/AgentForm";
import SideBar from "./sideBar";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import "./home.scss";
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import Footer from "../../components/Footer/index";
import CircularProgress from '@mui/material/CircularProgress';
import { axiosClient } from "../../axios.config";

function Home() {
    const [isAgentIDValid, setAgentIDValid] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [agentLogoUrl, setLogoUrl] = useState('');
    const [agentID, setAgentID] = useState('');
    const [isEmailValidationEnabled, setEmailEnable] = useState(false);
    const [isCaptchaEnabled, setCaptchaEnable] = useState(true)
    const [contactUsEmail, setContactUsEmail] = useState("")

    useEffect(() => {
        const id = window.location.pathname.slice(1);
        setAgentID(id);

        setLoading(true);
        axiosClient.get(`/geo-buyer/services/agent/affiliation/${id}`)
            .then(res => {
                setLoading(false);
                if (!res.data.errorCode && res.data.logoUrl) {
                    setAgentIDValid(true);
                    setEmailEnable(res.data.emailValidationEnabled);
                    setCaptchaEnable(res.data.captchaEnabled);
                    setLogoUrl(process.env.REACT_APP_API_URL + '/geo-buyer/' + res.data.logoUrl)
                    setContactUsEmail(res.data.contactUsEmail)
                }
            })
            .catch(err => console.error(err))
    }, [])

    if (!isAgentIDValid && isLoading) {
        return ("")
    }

    if (!isAgentIDValid) {
        return(<div>Please verify you have entered the correct URL.</div>)
    }
    

    return (
        <div className="home-wrapper">
            {isLoading && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', width: '100%', height: '100vh', zIndex:'10', left: 0 }}>
                <CircularProgress />
            </Box>}
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <SideBar agentLogoUrl={agentLogoUrl} contactUsEmail={contactUsEmail}/>
                </Grid>

                <Grid item xs={12} sm={8} className="section-2-wrapper">
                    <Box component="div" sx={{ p: 2, border: '1px dashed', mb: 2 }}>
                        <Typography variant="h3" gutterBottom>
                            For counsellors only
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            The below form is for counsellors to complete only when you are unable
                            to locate the education institution directly on the Convera payment platform.
                            Entries are validated against the Payment Reference Number and Student Email
                            fields in order to submit.
                        </Typography>
                    </Box>
                    <AgentForm agentID={agentID} setLoading={(loading) => setLoading(loading)}
                        isEmailValidationEnabled={isEmailValidationEnabled}
                        isCaptchaEnabled={isCaptchaEnabled}
                    />
                </Grid>
            </Grid> 
            <Footer />
        </div>
    );
}

export default Home;
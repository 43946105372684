import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  p: 4,
  textAlign: "center",
  outline: "none",
};

export default function GenericModal({ open, handleClose, message, success }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {success ? (
            <CheckCircleIcon style={{ color: "green", fontSize: 50 }} />
          ) : (
            <CancelIcon style={{ color: "red", fontSize: 50 }} />
          )}
          <Typography id="modal-modal-description" sx={{ my: 2 }}>
            {message}
          </Typography>
          <Button
            onClick={handleClose}
            className="close-button"
            variant="contained"
            disableElevation
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

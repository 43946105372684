export const ERROR_MESSAGES = {
  required: "This field is required",
  email: "Please enter a valid email",
  payRef: "Please enter a valid payment reference",
  name: "Please enter a valid name",
  agencyName:
    "Please use alphanumeric characters only for the agency name and remove any special characters or symbols.",
  deal_email_mismatch:
    "Payment Reference and Student Email Address must match our records to complete submission of form. Please confirm details and attempt again.",
  deal_email_valid:
    "Payment Reference and Student Email Address have been validated.",
  deal_mismatch:
    "Payment Reference must match our records to complete submission of form. Please confirm the Payment Reference and attempt again.",
  deal_valid: "Payment Reference validated.",
  DEAL_REFERENCE_ALREADY_CLAIMED:
    "This payment record is already associated with a counsellor. Please enter unclaimed payment record details only.",
};

export const PATTERNS = {
  email: /^[a-zA-Z0-9.!#$%&�*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  name: /^[A-Za-z ]+$/i,
  agencyName: /^[A-Za-z\d ]+$/i,
};

export const INITIAL_VALUES = {
  dealReference: "",
  studentEmail: "",
  institutionName: "",
  affiliateName: "",
  affiliateEmail: "",
  agencyName: "",
  agencyAddress: "",
  comments: "",
};

export const VALIDATIONS = {
  dealReference: {
    required: ERROR_MESSAGES.required,
    minLength: {
      value: 10,
      message: ERROR_MESSAGES.payRef,
    },
  },
  studentEmail: {
    required: ERROR_MESSAGES.required,
    pattern: {
      value: PATTERNS.email,
      message: ERROR_MESSAGES.email,
    },
  },
  institutionName: {},
  affiliateName: {
    required: ERROR_MESSAGES.required,
    pattern: {
      value: PATTERNS.name,
      message: ERROR_MESSAGES.name,
    },
  },
  affiliateEmail: {
    required: ERROR_MESSAGES.required,
    pattern: {
      value: PATTERNS.email,
      message: ERROR_MESSAGES.email,
    },
  },
  agencyName: {
    required: ERROR_MESSAGES.required,
    pattern: {
      value: PATTERNS.agencyName,
      message: ERROR_MESSAGES.agencyName,
    },
  },
  agencyAddress: {
    required: ERROR_MESSAGES.required,
  },
  comments: {},
};

import Typography from "@mui/material/Typography";

function Footer() {
  return (
    <div className="footer-wrapper">
      <Typography variant="caption" sx={{ py: 1 }}>
        Convera UK Limited (registered in England and Wales, Company Number
        04380026, Registered Office Address: Alphabeta Building, 14-18 Finsbury
        Square, London EC2A 1AH) is authorised by the Financial Conduct
        Authority under the Payment Services Regulations 2020 (Register
        Reference: 517165) for the provision of payment services and is
        registered as a Money Service Business with HM Revenue & Customs
        (Registered No: 12140130).
      </Typography>
    </div>
  );
}

export default Footer;

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function SideBar({ agentLogoUrl, contactUsEmail }) {
  return (
    <Box
      component="div"
      sx={{
        backgroundColor: "#001E37",
        color: "white",
        height: "100%",
        position: "relative",
        px: 3,
      }}
    >
      <Box
        component="div"
        sx={{ p: 0, display: "flex", alignItems: "center", pt: "35vh", mb: 4 }}
      >
        <div className="convera-img-wrapper">
          <img
            src="/images/convera-logo.png"
            alt="Conver logo"
            className="logo-img"
          />
        </div>
        <div className="banner-img-wrapper">
          <div className="baanner-img-inner-wrap">
            <img src={agentLogoUrl} alt="" className="banner-img" />
          </div>
        </div>
      </Box>
      <Typography variant="h1" gutterBottom>
        Counsellor Tracking <br></br> Form
      </Typography>
      <div className="need-help">
        <span>
          Need help?{" "}
          {!contactUsEmail ? <a
            href={`${process.env.REACT_APP_API_URL}/#!/contact-us`}
            target="_blank"
          >
            Contact us
          </a> : <a href={`mailto:${contactUsEmail}`}>{contactUsEmail}</a> }
        </span>
      </div>
    </Box>
  );
}

export default SideBar;

import React, { useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import ReCAPTCHA from "react-google-recaptcha";
import FormHelperText from "@mui/material/FormHelperText";
import { axiosClient } from "../../axios.config";

import {
  ERROR_MESSAGES,
  PATTERNS,
  INITIAL_VALUES,
  VALIDATIONS,
} from "./formhelper";
import { useEffect } from "react";
import GenericModal from "../Modal";

const AgentForm = ({
  agentID,
  setLoading,
  isCaptchaEnabled,
  isEmailValidationEnabled,
}) => {
  const recaptchaRef = useRef(null);

  const [formData, setFormData] = useState(INITIAL_VALUES);
  const [errors, setErrors] = useState(INITIAL_VALUES);
  const [dealRefLoading, setDealLoading] = useState(false);
  const [studentEmailLoading, setStudentEmailLoading] = useState(false);
  const [isDealValid, setDealValid] = useState(false);
  const [isDealValidated, setDealValidated] = useState(false);
  const [dealValidationMsg, setDealMessage] = useState("");
  const [isFormValid, setFormValid] = useState(false);
  const [modalData, setModalData] = useState({
    open: false,
    message: "",
    success: false,
  });

  useEffect(() => {
    hasValidationErrors();
  }, []);

  const handleModalClose = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (
      name == "dealReference" ||
      (name === "studentEmail" && isEmailValidationEnabled)
    ) {
      setDealValid(false);
      setDealValidated(false);
      setDealMessage("");
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    hasValidationErrors();
  };

  const validateField = (name, value, ignoreDealValidation) => {
    const fieldValidations = VALIDATIONS[name];
    let error = "";

    if (fieldValidations) {
      if (fieldValidations.required && !value.trim()) {
        error = fieldValidations.required;
      } else if (
        fieldValidations.pattern &&
        !fieldValidations.pattern.value.test(value)
      ) {
        error = fieldValidations.pattern.message;
      } else if (
        fieldValidations.minLength &&
        value.length < fieldValidations.minLength.value
      ) {
        error = fieldValidations.minLength.message;
      } else if (
        !ignoreDealValidation &&
        name === "studentEmail" &&
        formData.dealReference &&
        isEmailValidationEnabled
      ) {
        validateDealRef(name);
      } else if (
        !ignoreDealValidation &&
        name === "dealReference" &&
        (isEmailValidationEnabled ? formData.studentEmail : true)
      ) {
        validateDealRef(name);
      }
    }

    return error;
  };

  const validateDealRef = (triggeredBy) => {
    let data = {
      affiliateId: agentID,
      dealReference: formData.dealReference?.trim(),
      studentEmail: formData.studentEmail?.trim(),
    };

    triggeredBy === "dealReference"
      ? setDealLoading(true)
      : setStudentEmailLoading(true);

    return axiosClient
      .post(`/geo-buyer/services/agent/affiliation/deal/validate`, data)
      .then((res) => {
        setDealValidated(true);
        triggeredBy === "dealReference"
          ? setDealLoading(false)
          : setStudentEmailLoading(false);

        if (res.data.validDeal) {
          setFormData({
            ...formData,
            institutionName: res.data.institutionName,
          });
          setDealMessage(
            isEmailValidationEnabled
              ? ERROR_MESSAGES.deal_email_valid
              : ERROR_MESSAGES.deal_valid
          );
          setDealValid(true);
          return true;
        } else {
          setFormData({ ...formData, institutionName: "" });
          setDealMessage(
            res.data.errorCode === "DEAL_REFERENCE_ALREADY_CLAIMED"
              ? ERROR_MESSAGES.DEAL_REFERENCE_ALREADY_CLAIMED
              : isEmailValidationEnabled
              ? ERROR_MESSAGES.deal_email_mismatch
              : ERROR_MESSAGES.deal_mismatch
          );
          setDealValid(false);
          return false;
        }
      })
      .catch((err) => console.error(err));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    const error = validateField(name, value);
    setErrors({
      ...errors,
      [name]: error,
    });

    hasValidationErrors();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isCaptchaEnabled) {
      recaptchaRef.current.execute();
    } else {
      submitForm(formData, "");
    }
  };

  const reset = () => {
    setFormData(INITIAL_VALUES);
    setErrors(INITIAL_VALUES);
    setDealMessage("");
    setDealValid(false);
  };

  const handleRecaptchaChange = (response) => {
    if (response) {
      submitForm(formData, response);
    } else {
      // display captcha invalid/not verified error
    }
  };

  const submitForm = (formData, recaptchaResponse) => {
    setLoading(true);
    delete formData["institutionName"];
    axiosClient
      .post(`/geo-buyer/services/agent/affiliation/submit`, {
        ...formData,
        affiliateId: agentID,
        recaptchaResponse,
      })
      .then((res) => {
        setLoading(false);
        setDealValidated(false);
        if (!res.data.errorCode) {
          setDealMessage("");
          setDealValid(false);
          reset();
          setModalData({
            open: true,
            message: "Form submitted successfully!",
            success: true,
          });
        } else {
          setModalData({
            open: true,
            message: "Form submission failed. Please try again.",
            success: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const hasValidationErrors = () => {
    let formValid = Object.keys(VALIDATIONS).some((fieldName) => {
      const error = validateField(fieldName, formData[fieldName], true);
      return !!error;
    });

    setFormValid(!formValid);
  };

  return (
    <>
      <GenericModal handleClose={() => handleModalClose()} {...modalData} />
      <form className="agent-form-wrapper" onSubmit={handleSubmit}>
        <Typography variant="h3" gutterBottom>
          Student information
        </Typography>

        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <label>Payment reference number*</label>
            <TextField
              label=""
              inputProps={{
                maxLength: 28,
                placeholder: "Payment reference number",
              }}
              InputProps={{
                endAdornment: dealRefLoading ? (
                  <InputAdornment
                    position="start"
                    sx={{ backgroundColor: "white" }}
                  >
                    <CircularProgress size={15} />
                  </InputAdornment>
                ) : (
                  ""
                ),
              }}
              name="dealReference"
              value={formData.dealReference}
              onChange={handleInputChange}
              onBlur={handleBlur}
              error={
                !!errors.dealReference || (isDealValidated && !isDealValid)
              }
              helperText={errors.dealReference}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <label>Student email address*</label>
            <TextField
              label=""
              inputProps={{ placeholder: "Student email address" }}
              InputProps={{
                endAdornment: studentEmailLoading ? (
                  <InputAdornment
                    position="start"
                    sx={{ backgroundColor: "white" }}
                  >
                    <CircularProgress size={15} />
                  </InputAdornment>
                ) : (
                  ""
                ),
              }}
              name="studentEmail"
              value={formData.studentEmail}
              onChange={handleInputChange}
              onBlur={handleBlur}
              error={
                !!errors.studentEmail ||
                (isEmailValidationEnabled
                  ? isDealValidated && !isDealValid
                  : false)
              }
              helperText={errors.studentEmail}
              fullWidth
            />
          </Grid>

          {dealValidationMsg && (
            <Grid item xs={12} sx={{ pt: 0 }}>
              <FormHelperText
                error={!isDealValid}
                sx={{
                  position: "relative",
                  bottom: 0,
                  color: isDealValid ? "#00C7B3" : "",
                }}
              >
                {dealValidationMsg}
              </FormHelperText>
            </Grid>
          )}

          <Grid item xs={12}>
            <label>Institution name</label>
            <TextField
              label=""
              inputProps={{ placeholder: "Institution name" }}
              disabled
              name="institutionName"
              value={formData.institutionName}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h3" gutterBottom>
          Counsellor information
        </Typography>

        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <label>Counsellor name*</label>
            <TextField
              label=""
              name="affiliateName"
              value={formData.affiliateName}
              variant="outlined"
              inputProps={{ placeholder: "Counsellor name" }}
              error={!!errors.affiliateName}
              helperText={errors.affiliateName}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Counsellor email*</label>
            <TextField
              label=""
              name="affiliateEmail"
              value={formData.affiliateEmail}
              variant="outlined"
              inputProps={{ placeholder: "Counsellor email" }}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
              error={!!errors.affiliateEmail}
              helperText={errors.affiliateEmail}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <label>Agency name*</label>
            <TextField
              label=""
              name="agencyName"
              value={formData.agencyName}
              variant="outlined"
              inputProps={{ placeholder: "Agency name" }}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
              error={!!errors.agencyName}
              helperText={errors.agencyName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Agency address*</label>
            <TextField
              label=""
              name="agencyAddress"
              value={formData.agencyAddress}
              variant="outlined"
              inputProps={{ placeholder: "Agency address" }}
              error={!!errors.agencyAddress}
              helperText={errors.agencyAddress}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <label>Comments (optional)</label>
            <TextField
              label=""
              name="comments"
              value={formData.comments}
              error={!!errors.comments}
              helperText={errors.comments}
              variant="outlined"
              inputProps={{ placeholder: "Comments", maxLength: 100 }}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>

        {isCaptchaEnabled && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
            size="invisible"
            onChange={handleRecaptchaChange}
          />
        )}

        <Button
          variant="contained"
          className="submit-button"
          type="submit"
          disableElevation
          disabled={!isFormValid || !isDealValid}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default AgentForm;
